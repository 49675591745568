
import React, { useState, useCallback } from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Brush,

  ResponsiveContainer,
} from 'recharts';
import { LiaLongArrowAltRightSolid, LiaLongArrowAltUpSolid } from "react-icons/lia";

// const report_data = [

//   {
//     'name': `Test 77`,
//     'AttemptedQues': parseInt( Math.floor(Math.random() * 100) + 6),
//     'UnattemptedQues': parseInt( Math.floor(Math.random() * 100) + 5),
//     'CorrectAnswers': parseInt( Math.floor(Math.random() * 100) + 4),
//     'IncorrectAnswers': parseInt( Math.floor(Math.random() * 100) + 3),
//     'ObtainedMarks':parseInt( Math.floor(Math.random() * 100) + 1),
//     'Accuracyin':parseInt( Math.floor(Math.random() * 100) + 2),
//     'amt': 2400,
//   },







// ];




function XYZreportChart(report) {

  const { mode } = report
  console.log(report.report_data)
  const convertedData = report.report_data.map(item => ({
    ...item,
    testName: item.testName.replace(/&lt;/g, '<')
  }));
  console.log(convertedData)
  // zoom() {
  //   let { refAreaLeft, refAreaRight } = state;
  //   const { data } = state;

  //   if (refAreaLeft === refAreaRight || refAreaRight === '') {
  //     setState(() => ({
  //       refAreaLeft: '',
  //       refAreaRight: '',
  //     }));
  //     return;
  //   }

  //   // xAxis domain
  //   if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

  //   // yAxis domain
  //   const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, 'cost', 1);
  //   const [bottom2, top2] = getAxisYDomain(refAreaLeft, refAreaRight, 'impression', 50);

  //   setState(() => ({
  //     refAreaLeft: '',
  //     refAreaRight: '',
  //     data: data.slice(),
  //     left: refAreaLeft,
  //     right: refAreaRight,
  //     bottom,
  //     top,
  //     bottom2,
  //     top2,
  //   }));
  // }

  // zoomOut() {
  //   const { data } = state;
  //   setState(() => ({
  //     data: data.slice(),
  //     refAreaLeft: '',
  //     refAreaRight: '',
  //     left: 'dataMin',
  //     right: 'dataMax',
  //     top: 'dataMax+1',
  //     bottom: 'dataMin',
  //     top2: 'dataMax+50',
  //     bottom2: 'dataMin+50',
  //   }));
  // }
  const [strokewidth, setStrokewidth] = useState({
    attempted_question: 5,
    unAttempted_question: 5,
    correct_answer: 5,
    incorrect_answer: 5,
    obtained_Marks: 5,
    accuracy: 5,
  });

  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    setStrokewidth((prevstrokewidth) => ({
      ...prevstrokewidth,
      [dataKey]: 8,
    }));
  };

  const handleMouseLeave = (o) => {
    const { dataKey } = o;
    setStrokewidth((prevstrokewidth) => ({
      ...prevstrokewidth,
      [dataKey]: 5,
    }));
  };

  return (
    <div className="col-lg-12" style={{ background: mode == "dark" ? "black" : "white", color: mode === "dark" && "white" }}>
      <h2 className="report_other_headings" style={{ color: mode === "dark" && "white" }}>
        All Tests Analysis Report
      </h2>
      <div id='xyz_chart' className=" test_card_dark_blue" style={{ background: mode == "dark" ? "black" : "white", color: mode === "dark" && "white" }}>
        <div style={{ width: '100%', overflow: 'scroll', height: 350 }}>
          <div className='flex align-items-center justify-content-between'>
            <p className='flex align-items-center font-bold'>Y-Axis </p>
            <p className='flex align-items-center font-bold'>Z-Axis </p>

          </div>
          <i className='absolute top-1/3 text-3xl'><LiaLongArrowAltUpSolid /></i>
          <i className='absolute top-1/3 right-0 text-3xl'><LiaLongArrowAltUpSolid /></i>
          <ResponsiveContainer>
            <LineChart

              data={convertedData}

              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            // onMouseDown={(e) => setState({ refAreaLeft: e.activeLabel })}
            // onMouseMove={(e) => state.refAreaLeft && setState({ refAreaRight: e.activeLabel })}
            // // eslint-disable-next-line react/jsx-no-bind
            // onMouseUp={zoom.bind(this)}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey='testName' />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" unit="%" />

              <Tooltip />

              <Legend
              // onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
              />

              <Line
                yAxisId="left"
                type="monotone"
                dataKey="attempted_question"
                stroke="#5B9BD5"
                strokeWidth={strokewidth.attempted_question}
                dot={{ r: 0 }} activeDot={{ r: 6 }}
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="unAttempted_question"
                stroke="#ED7D31"
                strokeWidth={strokewidth.unAttempted_question}
                dot={{ r: 0 }} activeDot={{ r: 6 }}
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="correct_answer"
                stroke="#A5A5A5"
                strokeWidth={strokewidth.correct_answer}
                dot={{ r: 0 }} activeDot={{ r: 6 }}
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="incorrect_answer"
                stroke="#FFC000"
                strokeWidth={strokewidth.incorrect_answer}
                dot={{ r: 0 }} activeDot={{ r: 6 }}
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="obtained_Marks"
                stroke="#4472C4"
                strokeWidth={strokewidth.obtained_Marks}
                dot={{ r: 0 }} activeDot={{ r: 6 }}
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="accuracy"
                stroke="#70AD47"
                strokeWidth={strokewidth.accuracy}
                dot={{ r: 0 }} activeDot={{ r: 6 }}
              />
              <Brush dataKey="name" height={20} startInde={0} endIndex={convertedData.length >= 20 ? 8 : convertedData?.length - 1} stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
          <p className='flex align-items-center font-bold'>X-Axis <i className=' text-3xl'><LiaLongArrowAltRightSolid /></i></p>
        </div>

        <p className='flex align-items-center justify-center'>
          <p className='font-bold mb-0 text-xl flex justify-center align-items-center '>NB</p>: In the graph with three axes, note that the accuracy values displayed on the right-hand side are represented in percentage(%).
        </p>
      </div>

    </div>
  )
}

export default XYZreportChart