import React from 'react'
import Logo from '../../assets/images/logo.png'
import white from '../../assets/images/logo-white.png'
import profile from '../../assets/images/profile/profile.png'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { IoIosArrowForward } from "react-icons/io";
import { Fragment, useState,useRef,useEffect } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { ToastContainer, toast } from 'react-toastify'
import { AiOutlineClose, AiOutlineMenu, AiOutlineUser } from 'react-icons/ai'
import { FiLogOut, FiLock } from 'react-icons/fi'
import { MdOutlineCelebration } from 'react-icons/md'
import { CgLogOut } from 'react-icons/cg'
import axios from 'axios'
import { BsTicketPerforated } from 'react-icons/bs'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { userContext } from '../../Context/UserContextProvider'
import ReactHtmlParser   from 'html-react-parser';
import UseAxios from '../../util/UseAxios'
import LogoutModel from '../modal/LogoutModel'
import ImportCoursePopup from '../modal/coursePopup/ImportCoursePopup'
import Cookies from 'js-cookie'
// import {
//   ArrowPathIcon,
//   Bars3Icon,
//   ChartPieIcon,
//   CursorArrowRaysIcon,
//   FingerPrintIcon,
//   SquaresPlusIcon,
//   XMarkIcon,
// } from '@heroicons/react/24/outline'
// import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

// const products = [
//   { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
//   { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
//   { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
//   { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
//   { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
// ]
// const callsToAction = [
//   { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
//   { name: 'Contact sales', href: '#', icon: PhoneIcon },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const cate_btn = {
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  textTransform: 'capitalize',
  color: '#7D7D7D',
  padding: '0',

  fontFamily: "'Outfit', sans-serif",

}

function LoginNavbar() {
  const location = useLocation();
  const instance = UseAxios()

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const {contextcart_length,
    contextwislist_length,
    wishlistitemslength,
    cartitemslength,
    notification_lenght,
    mode,
    fetchnotificationData,profiledata,setProfiledata}=React.useContext(userContext)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const [anchorE2, setAnchorE2] = React.useState(null)
  const open2 = Boolean(anchorE2)
  const handleClickc = (event) => {
    setAnchorE2(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorE2(null)
    setAnchorEl(null)
  }
  const handleCourse = (course) => {
   
    // localStorage.clear()
    localStorage.setItem('course', course)
    setAnchorE2(null) 
  }
  const usertoken = localStorage.getItem('user_token')
  const [storeresponseData,setStoreresponseData]=useState("SSC Exams")
  let [responseData, setResponseData] = React.useState([])
  let [errorData, setErrorData] = React.useState('')
 
    const fetchData = async () => {
      try {
        const response = await instance.get('/api/v1/courses/category')
        if (Array.isArray(response.data.categories)) {
          setResponseData(response.data.categories)
          // console.log(response.data.categories);
        } else {
          console.log('newnew')
        }
      } catch (error) {
        setErrorData(error)
      }
    }
  React.useEffect(() => {
    fetchData()
    wishlistitemslength()
    cartitemslength()
    fetchnotificationData()
  }, [usertoken])

  const navigate = useNavigate()
  // logout
  const [Logouteopen, setLogouteOpen] = useState(false)
  const LogouthandleShow = (e) => {
    handleClose()
    return e.preventDefault(), setLogouteOpen(true)
  }

  const logout = (event) => {
    event.preventDefault()
    localStorage.clear()
    navigate('/signIn')
  }


  // showe profile image author:Nitesh
  const [spinner, setSpinner] = React.useState(false)
  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const response = await instance.get('/api/v1/auth/profile/')
        console.log(response.data.user)
        // setResponseData(response.data.user)
        let userdata =  JSON.stringify(response.data.user)
        Cookies.set('user_info', userdata)
        // setErName(response.data.user.name)
        setProfiledata(response.data.user)
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])

  return (
    <>
     {/* <ToastContainer /> */}
      {/* <div className="border border-b-2">
        <div className="flex items-center justify-between px-5">
          <div className="flex justify-between items-center">
            <div className="">
              <img src={Logo} alt="" />
            </div>
            <div className="md:flex justify-end mx-10 items-center hidden">
              <div className="px-5 font-bold">
                <Link to="/">Home</Link>
              </div>
              <div className="px-5">
                <Link to="/setting">Settings</Link>
              </div>
              <div className="px-5">
                <Link to="/refer-and-earn">Refer & Earn</Link>
              </div>
            </div>
          </div>
          <div className="md:flex items-center justify-around p-5 hidden">
            <div className="text-[#775EA5] mx-3 font-[500] px-2">
              <div className="relative">
                <i className="fa fa-bell-o border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2"></i>
                <div className="absolute bg-[#66BE5E] w-3 h-3 rounded-full right-[-5px] top-[-5px]"></div>
              </div>
            </div>
            <Link to="/my-profile" className="flex items-center">
              <div className="mx-3 text-white p-2 rounded text-center font-Outfi flex items-center">
                <div>
                  <img src={profile} className="rounded-full inline" alt="" />
                </div>
              </div>
              <div>
                <i className="fa fa-caret-down"></i>
              </div>
            </Link>
          </div>
          <div className="md:hidden block float-right">
            <span className="w-9 h-1 bg-[#775EA5] m-1 block"></span>
            <span className="w-9 h-1 bg-[#775EA5] m-1 block"></span>
            <span className="w-9 h-1 bg-[#775EA5] m-1 block"></span>
          </div>
        </div>
      </div> */}

      <header className="bg-white main_login_header" >
        <nav
        style={{background:mode=="dark"&&"#151515"}}
          className="mx-auto flex max-w-8xl items-center justify-between p-1 lg:px-0 bg-[#f5f5f5]"
          aria-label="Global"
        >
          <div className="flex lg:flex-2">
            <a href="#" className="-m-1.5 p-1.5">

              <Link to="/" style={{color:mode=="dark"&&"white"}}>
                <img className="logo" src={mode==="dark"? white :Logo} alt="" />
              </Link>
              
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <AiOutlineMenu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          
             
         
          <Popover.Group className="hidden lg:flex lg:gap-x-8 login_navbar_side">
          <Link style={{color:mode=="dark"&&"white"}} to="/" className=" text-[#7D7D7D] font-[500]  font-Outfit mr-1 menu_links">
                    Home
                  </Link>
            <div className="px-0 font-bold">  
              <Link 
              style={{color:mode=="dark"&&"white"}}
                className={`text-sm font-semibold leading-6 text-gray-700 ${
                  location.pathname === '/myorder' ? `${mode==="dark" ? "dark_nav_active":"nav_active"}` : ''
                }`}
                to="/myorder"
              >
                Dashboard
              </Link>
            </div>
            <ImportCoursePopup/>
        

            {/* <Button
                  className="menu_links mx-2"
                  id="basic-button"
                  aria-controls={open2 ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? 'true' : undefined}
                  onClick={handleClickc}
                  endIcon={<KeyboardArrowDownIcon className='!text-gray-700'/>}
                  style={cate_btn}
                >
                  Courses
                </Button> */}


            <div className="">
              <Link style={{color:mode=="dark"&&"white"}}
                className={`text-sm font-semibold leading-6 text-gray-700 ${
                  location.pathname === '/setting' ? `${mode==="dark" ? "dark_nav_active":"nav_active"}` : ''
                }`}
                to="/setting"
              >
                Settings
              </Link>
            </div>
            <div className="">
              <Link style={{color:mode=="dark"&&"white"}}
                className={`text-sm font-semibold leading-6 text-gray-700 ${
                  location.pathname === '/refer-and-earn' ? `${mode==="dark" ? "dark_nav_active":"nav_active"}` : ''
                }`}
                to="/refer-and-earn"
              >
                Refer & Earn
              </Link>
            </div>
            <div>
              <a  style={{color:mode=="dark"&&"white"}} href='/scholarship'>Scholarship</a>
            </div>
          </Popover.Group>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
            <div className="text-[#775EA5] mx-1 font-[500]  hidden md:block">
              <Link to="/wishlist" style={{color:mode=="dark"&&"white"}} >
              <div className="relative">
                <i style={{color:mode=="dark"&&"white"}} className="fa fa-heart-o border w-full h-full border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2 btn_hover_round"></i>
                {contextwislist_length != '0'  && <div 
                    className="absolute bg-[#66BE5E] w-4 h-4 rounded-full right-[-5px] top-[-5px] flex justify-center items-center text-white text-xs"
                    >{contextwislist_length}
                    </div>}
                    </div>
              </Link>
            </div>
            <Link
            style={{color:mode=="dark"&&"white"}}
              to="/order_details/"
              className="text-[#775EA5] mx-2 font-[500] px-2 hidden md:block"
            >
              <div className="relative">
                <i  style={{color:mode=="dark"&&"white"}} className="fa fa-shopping-cart border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2 btn_hover_round"></i>
                {contextcart_length != '0' && <div 
                    className="absolute bg-[#66BE5E] w-4 h-4 rounded-full right-[-5px] top-[-5px] flex justify-center items-center text-white text-xs"
                    >{contextcart_length}</div>}
              </div>
            </Link>

            <div className="text-[#775EA5] mr-3 font-[500] px-2 ">
              <Link to="/notification" style={{color:mode=="dark"&&"white"}} >
                <div className="relative">
                  <i style={{color:mode=="dark"&&"white"}} className="fa fa-bell-o border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2 btn_hover_round"></i>
                  {notification_lenght.length != '0' && <div className="absolute bg-[#66BE5E] w-4 h-4 rounded-full right-[-5px] top-[-5px] flex justify-center items-center text-white text-xs">{notification_lenght.length}</div>}
                </div>
              </Link>
            </div>

            <div className="bg-[#66BE5E] text-white px-2 py-1 rounded w-[6rem] text-center font-Outfi hidden md:block btn_hover">
                    <button
                      onClick={LogouthandleShow}
                      className='flex justify-center items-center'
                      
                    >
                      <CgLogOut className='text-xl'/>
                      <span className='pl-1 block'>Logout</span>
                    </button>
                  </div>

            <div className="mx-3 text-white p-2 rounded text-center font-Outfi flex items-center">
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                {/* <img src={profileimgpath} className="rounded-full inline" alt="" /> */}
                <img
                     src={profiledata?.profile_image === '' 
                    || profiledata?.profile_image === undefined
                  || profiledata?.profile_image === null ? './plceholdernav/placeholder-img.png'
                  : profiledata?.profile_image }
                    alt="profile"
                    className=" user_img w-[40px] h-[40px] mx-auto object-cover rounded-full"
                  />
                <i className="fa fa-caret-down ml-3"></i>
              </Button>
            </div>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <img className="logo_small" src={Logo} alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <AiOutlineClose className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <div className=" font-bold">
                    <Link style={{color:mode=="dark"&&"white"}}
                      className="text-sm font-semibold leading-6 text-gray-900"
                      to="/"
                    >
                      Home
                    </Link>
                  </div>
                  <div className="">
                    <Link style={{color:mode=="dark"&&"white"}}
                      className="text-sm font-semibold leading-6 text-900"
                      to="/setting?page=termservice"
                    >
                      Settings
                    </Link>
                  </div>
                  <div className="">
                    <Link
                    style={{color:mode=="dark"&&"white"}}
                      className="text-sm font-semibold leading-6 text-gray-900"
                      to="/refer-and-earn"
                    >
                      Refer & Earn
                    </Link>
                  </div>
                  <div className="">
                    <button
                      onClick={logout}
                      className="text-sm font-semibold leading-6 text-red-600"
                      
                    >
                      Logout
                    </button>
                  </div>
                </div>
                <div className="py-6 flex items-center">
                  {/* <div className="text-[#775EA5] ">
                    <Link to="/wishlist">
                      <i className="fa fa-heart-o "></i>
                    </Link>
                  </div>
                  <Link
                    to="/order_details/"
                    className="text-[#775EA5] "
                  >
                    <div className="relative">
                      <i className="fa fa-shopping-cart "></i>
                      <div className="absolute "></div>
                    </div>
                  </Link> */}

                  <div className="text-[#775EA5] mx-1 font-[500]  hidden md:block">
                    <Link to="/wishlist"
                    style={{color:mode=="dark"&&"white"}}
                    >
                      <i className="fa fa-heart-o border w-full h-full border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2"></i>
                    </Link>
                  </div>
                  <Link
                    to="/order_details/"
                    style={{color:mode=="dark"&&"white"}}
                    className="text-[#775EA5] mx-2 font-[500] px-2 hidden md:block"
                  >
                    <div className="relative">
                      <i className="fa fa-shopping-cart border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2"></i>
                      <div className="absolute bg-[red] w-3 h-3 rounded-full right-[-5px] top-[-5px]"></div>
                    </div>
                  </Link>

                  <div className="text-[#775EA5] mx-3 font-[500] px-2 ">
                    <div className="relative" style={{ display: 'inline' }}>
                      <i className="fa fa-bell-o border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2 "></i>
                      <div className="absolute bg-[#66BE5E] w-3 h-3 rounded-full right-[-5px] top-[-5px]"></div>
                    </div>
                  </div>

                  <div className="text-[#775EA5] mx-3 font-[500] px-2 ">
                    <div className="relative" style={{ display: 'inline' }}>
                      <i className="fa fa-bell-o border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2"></i>
                      <div className="absolute bg-[#66BE5E] w-3 h-3 rounded-full right-[-5px] top-[-5px]"></div>
                    </div>
                  </div>

                  <div className="text-[#775EA5] mx-3 font-[500] px-2 ">
                    <div className="relative" style={{ display: 'inline' }}>
                      <i className="fa fa-bell-o border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2"></i>
                      <div className="absolute bg-[#66BE5E] w-3 h-3 rounded-full right-[-5px] top-[-5px]"></div>
                    </div>
                  </div>

                  <div className="mx-3 text-white p-2 rounded text-center font-Outfi flex items-center">
                    <Button
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <img
                        src={profile}
                        className="rounded-full inline"
                        alt=""
                      />
                      <i className="fa fa-caret-down ml-3"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#775EA5',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="profile_menu_drop_items_main ">
          <MenuItem className="profile_menu_drop_items" onClick={handleClose}>
            <Link to="/my-profile">
              <AiOutlineUser className="icons" /> personal information
            </Link>
          </MenuItem>
          <MenuItem className="profile_menu_drop_items" onClick={handleClose}>
            <Link to="/setting?page=changepassword">
              <FiLock className="icons" /> password
            </Link>
          </MenuItem>
          <MenuItem className="profile_menu_drop_items" onClick={handleClose}>
            <Link to="/setting?page=raiseticket">
              <BsTicketPerforated className="icons" /> Raise a ticket
            </Link>
          </MenuItem>
          <MenuItem className="profile_menu_drop_items" onClick={handleClose}>
            <Link to="/events">
              <MdOutlineCelebration className="icons" /> Events
            </Link>
          </MenuItem>
          <MenuItem
            className="profile_menu_drop_items logout_btns_nav"
            onClick={LogouthandleShow}
          >
            {/* <Link to="#"> */}
            <FiLogOut className="icons logout_btn" />{' '}
            <button
              onClick={LogouthandleShow}
              name=""
              id=""
              className="logout_btn"
              value=""
            >
              Logout
            </button>
            {/* </Link> */}
          </MenuItem>
        </div>
      </Menu>


      <Menu
        id="basic-menu"
        anchorE2={anchorE2}
        open={open2}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className="main_header_dropdown"
        
      >
        <div  className='bg-[#f5f5f5]'>
          {/* <div className="row"> */}
            {Array.isArray(responseData) ? (
              responseData.map((category) => (
                <div className="menu_data col-lg-12 col-md-6 col-sm-12 col-6 ">
                  <div className='flex header_cata'>
                    <div className='header_cata_heading w-[20%]' onMouseEnter={()=>setStoreresponseData(category?.category_name)}>
                      <h2 className="header_cate_name ">
                        <div className='Course_title_flex'>
                            <img src={category.category_image ==='' 
                            || category.category_image === undefined 
                            ||  category.category_image === null  
                            ? './plceholdernav/placeholder-img.png'
                            :category?.category_image} width={30}></img>
                           
                           {RegExp('&lt;').test(category.category_name) ? ReactHtmlParser(`${category?.category_name.replaceAll('&lt;', '<')}`) :category?.category_name}
                        </div>
                        <i><IoIosArrowForward/></i>
                      </h2>
                    </div>

                    { category.category_name === storeresponseData && <div className='header_cate_name_children grid grid-cols-4 gap-4 w-[80%] absolute right-0 p-4'>
                        {category.courses.map((course, index) => {
                          return (
                            <div className='header_cata_content bg-[#fff] ' key={index}>
                              <Link
                                to={`/course/${course._id}`}
                                onClick={() => handleCourse(course._id)}
                                className=" py-1 mt-2  rounded flex justify-around items-center"
                                >
                               <div className='Course_title_flex'>
                                  <img src={course.course_image === '' 
                                  || course.course_image === null 
                                  || course.course_image === undefined  
                                  ? './plceholdernav/placeholder-img.png'
                                  : `${course?.course_image}` } width={20}></img>
                                  <div className='white_space ps-1'>
                                  {RegExp('&lt;').test(course?.course_title) ? ReactHtmlParser(`${course?.course_title.replaceAll('&lt;', '<')}`) :course?.course_title}
                                  </div>
                               </div>
                              <i><IoIosArrowForward/></i>
                              </Link>
                            </div>
                          )
                        })}
                    </div>}
                  </div>
                </div>
              ))
            ) : (
              <p>No courses available.</p>
            )}
          {/* </div> */}
        </div>
      </Menu>

      {Logouteopen && <LogoutModel open={Logouteopen}  setOpen={setLogouteOpen}/> }
    </>
  )
}

export default LoginNavbar
